import req from "../methods/req"
import method from '../methods/method'

export const hotelLevelList = (data) => method.get_list({ url: '/manage/crm.hotel/hotelLevelList', ...data })

export const saveHotelLevel = (data) => req({ url: '/manage/crm.hotel/saveHotelLevel', ...data })
export const delHotelLevel = (data) => req({ url: '/manage/crm.hotel/delHotelLevel', ...data })

// manage/crm.trip/tripStandardList
export const tripStandardList = (data) => method.get_list({ url: '/manage/crm.trip/tripStandardList', ...data })

// manage/crm.trip/quotaSettingList

export const quotaSettingList = (data) => method.get_list({ url: '/manage/crm.trip/quotaSettingList', ...data })
// 人员选择

export const getUserToRole = (data) => req({ url: '/manage/crm.common/getUserToRole', ...data })

//manage/crm.trip/delQuotaSetting
export const delQuotaSetting = (data) => req({ url: '/manage/crm.trip/delQuotaSetting', ...data })



export const saveQuotaSetting = (data) => req({ url: '/manage/crm.trip/saveQuotaSetting', ...data })

//manage/crm.trip/quotaSettingDetail

export const quotaSettingDetail = (data) => req({ url: '/manage/crm.trip/quotaSettingDetail', ...data })

//manage/crm.hotel/delTripStandard

export const delTripStandard = (data) => req({ url: '/manage/crm.trip/delTripStandard', ...data })

// 酒店标准、交通标准manage/crm.hotel/getHotelSelect

export const getHotelSelect = (data) => req({ url: '/manage/crm.trip/getHotelSelect', ...data })


export const saveTripStandard = (data) => req({ url: '/manage/crm.trip/saveTripStandard', ...data })

// manage/crm.trip/tripStandardDetail

export const tripStandardDetail = (data) => req({ url: '/manage/crm.trip/tripStandardDetail', ...data })

// 


export const getArea = (data) => req({ url: '/manage/crm.common/getArea', ...data })

export const getTogetherStandard = function (data){
    return req({
        url:'/manage/crm.trip/getTogetherStandard ',
        ...data
    })
}



export const setTogetherStandard = function (data){
    return req({
        url:'/manage/crm.trip/setTogetherStandard',
        ...data
    })
}

// manage/crm.trip/getApprovalDetail

export const getTripPlaceDetail = function (data){
    return req({
        url:'/manage/crm.trip/getTripPlaceDetail',
        ...data
    })
}
export const getApprovalDetail = function (data){
    return req({
        url:'/manage/crm.trip/getApprovalDetail',
        ...data
    })
}

export const getHotelDetail = function (data){
    return req({
        url:'/manage/crm.hotel/getHotelDetail',
        ...data
    })
}

export const getOrderInfo = function (data){
    return req({
        url:'/manage/crm.hotel/getOrderInfo',
        ...data
    })
}
export const getCensus = function (data){
    return req({
        url:'/manage/crm.recharge/getCensus',
        ...data
    })
}


export const setRechargeTips = function (data){
    return req({
        url:'/manage/crm.recharge/setRechargeTips',
        ...data
    })
}
export const getRechargeConfig = function (data){
    return req({
        url:'/manage/crm.recharge/getRechargeConfig',
        ...data
    })
}

export const createOrder = function (data){
    return req({
        url:'/manage/crm.recharge/createOrder',
        ...data
    })
}

export const setInvoice = function (data){
    return req({
        url:'/manage/crm.recharge/setInvoice',
        ...data
    })
}

export const rechargeOrderInfo = function (data){
    return req({
        url:'/manage/crm.recharge/getOrderInfo',
        ...data
    })
}
//

export const orderCensus = function (data){
    return req({
        url:'/manage/crm.hotel/orderCensus',
        ...data
    })
}

export const clockInfo = function (data){
    return req({
        url:'/manage/crm.hotel/clockInfo',
        ...data
    })
}


export const clockCensus = function (data){
    return req({
        url:'/manage/crm.hotel/clockCensus',
        ...data
    })
}

export const changeHotelStatus = function (data){
    return req({
        url:'/manage/crm.hotel/changeHotelStatus',
        ...data
    })
}
// 

export const orderBilling = function (data){
    return req({
        url:'/manage/crm.recharge/orderBilling',
        ...data
    })
}


export const countApproval = function (data){
    return req({
        url:'/manage/crm.trip/countApproval',
        ...data
    })
}

export const orderCount = function (data){
    return req({
        url:'/manage/crm.hotel/orderCount',
        ...data
    })
}

export const getUserSelect = function (data){
    return req({
        url:'/manage/crm.common/getUserSelect',
        ...data
    })
}
export const exportTripPlace = function (data){
    return req({
        url:'/manage/crm.trip/exportTripPlace',
        ...data
    })
}

export const getOrderStatus = function (data){
    return req({
        url:'/manage/crm.hotel/getOrderStatus',
        ...data
    })
}

export const cityManageDetail = function (data){
    return req({
        url:'/manage/crm.city_manage/cityManageDetail',
        ...data
    })
}

export const cityManageSave = function (data){
    return req({
        url:'/manage/crm.city_manage/cityManageSave',
        ...data
    })
}

export const getApprovalTypeSelect = function (data){
    return req({
        url:'/manage/crm.trip/getApprovalTypeSelect',
        ...data
    })
}

// 上传凭证 
export const uploadVoucher = function (data){
    return req({
        url:'/manage/crm.recharge/uploadVoucher',
        ...data
    })
}

export const countTrip = function (data){
    return req({
        url:'/manage/crm.trip/countTrip',
        ...data
    })
}

export const getSubsidyInfo = function (data){
    return req({
        url:'/manage/crm.subsidy/getInfo',
        ...data
    })
}

export const changeStatus = function (data){
    return req({
        url:'/manage/crm.subsidy/changeStatus',
        ...data
    })
}

// 报表
// 头部数据
export const overViewCount = function (data){
    return req({
        url:'/manage/crm.trip_count/overViewCount',
        ...data
    })
}

// 酒店订单
export const hotelOrderCount = function (data){
    return req({
        url:'/manage/crm.trip_count/hotelOrderCount',
        ...data
    })
}



// 出差人数
export const personsNumCount = function (data){
    return req({
        url:'/manage/crm.trip_count/personsNumCount',
        ...data
    })
}



// 申请出差人
export const tripApprovalCount = function (data){
    return req({
        url:'/manage/crm.trip_count/tripApprovalCount',
        ...data
    })
}

export const tripPlaceCount = function (data){
    return req({
        url:'/manage/crm.trip_count/tripPlaceCount',
        ...data
    })
}


export const hotelDepartCount = function (data){
    return req({
        url:'/manage/crm.trip_count/hotelDepartCount',
        ...data
    })
}

export const hotelOrdainCount = function (data){
    return req({
        url:'/manage/crm.trip_count/hotelOrdainCount',
        ...data
    })
}

export const approvalTimeCount = function (data){
    return req({
        url:'/manage/crm.trip_count/approvalTimeCount',
        ...data
    })
}
export const clockSetting = function (data){
    return req({
        url:'/manage/crm.trip/clockSetting',
        ...data
    })
}

export const getClockSetting = function (data){
    return req({
        url:'/manage/crm.trip/getClockSetting',
        ...data
    })
}

export const getSpecialDetail = function (data){
    return req({
        url:'/manage/crm.trip/getSpecialDetail',
        ...data
    })
}
// 


export const setSpecialApprovalt = function (data){
    return req({
        url:'/manage/crm.trip/setSpecialApproval',
        ...data
    })
}
export const placeClockDetail = function (data){
    return req({
        url:'/manage/crm.trip/placeClockDetail',
        ...data
    })
}
export const placeClockCount = function (data){
    return req({
        url:'/manage/crm.trip/placeClockCount',
        ...data
    })
}


export const getSpecialList = (data) => method.get_list({  url:'/manage/crm.trip/getSpecialList', ...data })
export const placeClock = (data) => method.get_list({  url:'/manage/crm.trip/placeClock', ...data })

export const getList = (data) => method.get_list({  url:'/manage/crm.recharge/getList', ...data })

export const getTripPlace = (data) => method.get_list({  url:'/manage/crm.trip/getTripPlace', ...data })

export const getTripApproval = (data) => method.get_list({  url:'/manage/crm.trip/getTripApproval', ...data })

export const getHotelList = (data) => method.get_list({  url:'/manage/crm.hotel/getHotelList', ...data })

export const getHotelOrder = (data) => method.get_list({  url:'/manage/crm.hotel/getHotelOrder', ...data })
export const clockList = (data) => method.get_list({  url:'/manage/crm.hotel/clockList', ...data })
export const CityManageList = (data) => method.get_list({  url:'/manage/crm.city_manage/CityManageList', ...data })
export const getSubsidyList = (data) => method.get_list({  url:'/manage/crm.subsidy/getList', ...data })

// 差旅导出列表
export const tripList = (data) => method.get_list({  url:'/manage/crm.trip_export/tripList', ...data })

// 差旅账单导出excel文件的接口
export const tripExport = (data) => {return req({  url:'/manage/crm.trip_export/tripExport', ...data })}